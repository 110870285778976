import {
    Grid,
    makeStyles,
    Typography
} from "@material-ui/core";
import {
    StarBorderRounded,
    StarHalfRounded,
    StarRounded,
} from "@material-ui/icons";
import React from "react";

const useStyle = makeStyles((theme) => ({
    text: {
        marginLeft: theme.spacing(1),
    },
}));

/**
 * Component showing the five star rank
 * @param note number of star
 * @param text grade in string
 * @returns
 */

function StarsRank({ note = 0, text = false }) {
    const classes = useStyle();

    const star = [];
    for (let i = 0; i < 5; i++) {
        if (i < parseInt(note)) star.push(<StarRounded style={{fill: "orange"}} fontSize="small" />);
        else if (
            i === parseInt(note) &&
            note >= parseInt(note) + 0.5 &&
            note <= parseInt(note) + 1
        )
            star.push(<StarHalfRounded style={{fill: "orange"}} fontSize="small" />);
        else star.push(<StarBorderRounded style={{fill: "orange"}} fontSize="small" />);
    }
    return (
        <Grid container direction="row" alignItems="center">
            {star}
            {text && (
                <Typography
                    className={classes.text}
                    variant="h6"
                    component="h6"
                    color="secondary"
                >
                    {note % 1 ? note.toFixed(1) : note}/5
                </Typography>
            )}
        </Grid>
    );
}

export default StarsRank;
