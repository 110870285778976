import React, { useEffect, useState } from 'react'
import {
    Button,
    Typography,
    Paper,
    TextField,
    Box,
    Grid,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import Alert from '@mui/material/Alert';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { signin } from '../actions/userActions';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(10),
        justifyContent: 'center',
        display: "flex",
        position: "relative",
    },
    containerbox: {
        width: 800,
        justifyContent: "center",
        display: "flex",
    },
    emailinput: {
        width: 400,
    },
    pswinput: {
        width: 400,
    },
    titlemail: {
        display: "flex",
        justifyContent: "flex-start",
    },
    titlepsw: {
        display: "flex",
        justifyContent: "flex-start",
    },
    btnsignin: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    box: {
        width: 400,
        marginTop: theme.spacing(5),
    },
    signin: {
        textDecoration: "none",
        '&:hover': {
            color: "grey",
        }
    },
    alert: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}))

function SigninScreen(props) {

    const classes = useStyles()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');

    const redirect = props.location.search
        ? props.location.search.split('=')[1]
        : "/"

    const userSignin = useSelector((state) => state.userSignin)
    const { userInfo, loading, error } = userSignin

    const dispatch = useDispatch()

    const submitHandler = (e) => {
        e.preventDefault();
        dispatch(signin(email, password))
    }
    useEffect(() => {
        if (userInfo) {
            props.history.push(redirect)
        }
    }, [props.history, redirect, userInfo]);

    return (
        <div>
            <Grid container className={classes.root}>
                <form onSubmit={submitHandler} align="center">
                    <Paper className={classes.containerbox}>
                        <Box className={classes.box}>
                            <Typography variant="h6">Sign In</Typography>
                            <br />
                            {loading && <Typography></Typography>}
                            {error && <Alert variant="filled" severity="error" className={classes.alert}>{error}</Alert>}
                            {/* {error && <Alert variant="outlined" severity="error" className={classes.alert}>{error}</Alert>} */}
                            <Grid item>
                                <label htmlFor="email" className={classes.titlemail}>Email address</label>
                            </Grid>
                            <br />
                            <Grid item>
                                <TextField
                                    label="email"
                                    color="secondary"
                                    id="email"
                                    type="email"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    placeholder="Enter mail"
                                    required
                                    onChange={(e) => setEmail(e.target.value)}
                                    className={classes.emailinput}
                                ></TextField>
                            </Grid>
                            <br />
                            <Grid item>
                                <label htmlFor="password" className={classes.titlepsw}>Password</label>
                            </Grid>
                            <br />
                            <Grid item>
                                <TextField
                                    label="password"
                                    id="password"
                                    color="secondary"
                                    type="password"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    placeholder="Enter password"
                                    required
                                    onChange={(e) => setPassword(e.target.value)}
                                    className={classes.pswinput}
                                ></TextField>
                            </Grid>
                            <br />
                            <Grid item>
                                <Button
                                    type="submit"
                                    color="secondary"
                                    variant="contained"
                                    fullWidth
                                    className={classes.btnsignin}
                                >
                                    Sign In
                                </Button>
                                <Typography>New customer? &nbsp;
                                    <Link to={`/register?redirect=${redirect}`} className={classes.signin}>Create your account</Link>
                                </Typography>
                            </Grid>
                        </Box>
                    </Paper>
                </form>
            </Grid>
        </div>
    )
}

export default SigninScreen;
