import React from "react";
import Carousel from "react-material-ui-carousel"
import {
    Card,
    CardMedia,
    Typography,
    Grid,
    makeStyles,
    Divider,
} from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
    root: {
        marginTop: "100px",
        marginBottom: "100px",
        color: "#494949",
    },
    media: {
        height: "180px",
        width: "180px",
    },
    title: {
        display: "flex",
        marginLeft : theme.spacing(2),
        padding : theme.spacing(2),
    },
    price: {
        marginLeft: theme.spacing(1),
    },
    carousel: {
        marginLeft : theme.spacing(2),
    }
}))

function Banner(props) {

    const totalItems = props.length ? props.length : 6;
    const mediaLength = totalItems;

    const classes = useStyle()

    let items = [];


    for (let i = 0; i < mediaLength; i++) {
        const item = props.item.Items[i];

        const media = (
            <Grid item xs={12 / totalItems} key={item.Name}>
                <CardMedia
                    className={classes.media}
                    image={item.Image}
                // title={item.Name}
                >
                </CardMedia>
                <Typography className={classes.price}>{item.Price} €</Typography>
            </Grid>
        )
        items.push(media);
    }


    return (
        <Card raised className="Banner">
            <Grid container spacing={0} className="BannerGrid">
                {items}
            </Grid>
        </Card>
    )
}

const items = [
    {
        Items: [
            {
                Name: "Macbook Pro",
                Image: process.env.PUBLIC_URL + "assets/img/SousCarouselImg1.png",
                Price: Math.floor(Math.random() * 500) + 20,
            },
            {
                Name: "iPhone",
                Image: process.env.PUBLIC_URL + "assets/img/SousCarouselImg2.png",
                Price: Math.floor(Math.random() * 500) + 20,
            },
            {
                Name: "iPhone",
                Image: process.env.PUBLIC_URL + "assets/img/SousCarouselImg3.png",
                Price: Math.floor(Math.random() * 500) + 20,
            },
            {
                Name: "iPhone",
                Image: process.env.PUBLIC_URL + "assets/img/SousCarouselImg4.png",
                Price: Math.floor(Math.random() * 500) + 20,
            },
            {
                Name: "iPhone",
                Image: process.env.PUBLIC_URL + "assets/img/SousCarouselImg5.png",
                Price: Math.floor(Math.random() * 500) + 20,
            },
            {
                Name: "iPhone",
                Image: process.env.PUBLIC_URL + "assets/img/SousCarouselImg6.png",
                Price: Math.floor(Math.random() * 500) + 20,
            }
        ]
    },
    {
        Items: [
            {
                Name: "Macbook Pro",
                Image: process.env.PUBLIC_URL + "assets/img/SousCarouselImg1.png",
                Price: Math.floor(Math.random() * 500) + 20,
            },
            {
                Name: "iPhone",
                Image: process.env.PUBLIC_URL + "assets/img/SousCarouselImg2.png",
                Price: Math.floor(Math.random() * 500) + 20,
            },
            {
                Name: "iPhone",
                Image: process.env.PUBLIC_URL + "assets/img/SousCarouselImg3.png",
                Price: Math.floor(Math.random() * 500) + 20,
            },
            {
                Name: "iPhone",
                Image: process.env.PUBLIC_URL + "assets/img/SousCarouselImg4.png",
                Price: Math.floor(Math.random() * 500) + 20,
            },
            {
                Name: "iPhone",
                Image: process.env.PUBLIC_URL + "assets/img/SousCarouselImg5.png",
                Price: Math.floor(Math.random() * 500) + 20,
            },
            {
                Name: "iPhone",
                Image: process.env.PUBLIC_URL + "assets/img/SousCarouselImg6.png",
                Price: Math.floor(Math.random() * 500) + 20,
            }
        ]
    },
]

function SousCarouselBar() {

    const classes = useStyle()
    return (
        <div className={classes.root}>
            <Grid container className={classes.title} >
                <Typography variant="h6">Bons Plans</Typography>
                <Divider orientation="vertical" flexItem variant="middle" />
                <Typography variant="h6">Tout afficher</Typography>
            </Grid>
            <Carousel
            className={classes.carousel}
                autoPlay={false}
                animation={"fade"}
                indicators={false}
                timeout={100}
                cycleNavigation={true}
                navButtonsAlwaysVisible={false}
                navButtonsAlwaysInvisible={false}
            >
                {
                    items.map((item, index) => {
                        return <Banner item={item} key={index} contentPosition={item.contentPosition}
                            className={classes.banner}
                        />
                    })
                }
            </Carousel>
        </div>

    )
}


export default SousCarouselBar;
