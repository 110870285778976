import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Grid,
  Typography,
  CardMedia,
  Button,
  CircularProgress,
  Box,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import StarsRank from "../StarsRank";
import CarouselProductInfo from "./CarouselProductInfo";
import DescriptionProduct from "./DescriptionProduct";
import ScrollToTop from "../ScrollToTop";
import { useDispatch, useSelector, } from "react-redux";
import { detailsProduct } from "../../actions/productActions";

const useStyle = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(10),
  },
  notfound: {
    marginTop: theme.spacing(7),
  },
  detailImg: {
    height: "14rem",
    width: "14rem",
  },
  partTop: {
    display: "flex",
  },
  btnAddCart: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(5),
  },
  name: {
    marginBottom: theme.spacing(1),
  },
  color: {
    marginBottom: theme.spacing(1),
  },
  livraison: {
    marginBottom: theme.spacing(1),
  },
  paiement: {
    marginBottom: theme.spacing(1),
  },
  stock: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  oups: {
    // color: theme.palette.text.secondary,
    textAlign: "center",
    marginTop: theme.spacing(7),
  },
  loading: {
    marginTop: theme.spacing(8),
    justifyContent: "center",
  },
  addCart: {
    display: "flex",
  },
  btnAddCart:{
    width:"10rem",
    height:"3rem",
    fontSize:"0.5rem",
  },
  qty: {
    marginTop: theme.spacing(1.5),
  },
  textField: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
  }
}));


function ProductInfo(props) {

  const classes = useStyle();

  const dispatch = useDispatch()

  const productId = props.match.params.id

  const productDetails = useSelector((state) => state.productDetails)

  const { loading, error, product } = productDetails

  useEffect(() => {
    dispatch(detailsProduct(productId))
  }, [dispatch, productId]);

  // const [willBeUnfavoured, setWillBeUnfavoured] = useState(false);
  const [qty, setQty] = useState(1)

  const addToCartHandler = () => {
    props.history.push(`/cart/${productId}?qty=${qty}`)
    console.log("qty:" + qty);
  }

  const noResult = (
    <Typography variant="h4" className={classes.oups}>
      Oups ... on dirait qu'il n'y a pas de résultats
    </Typography>
  );

  const loadingBox = (
    <Box className={classes.loading} sx={{ display: 'flex' }}>
      <CircularProgress color="secondary" size={35} />
      <Typography variant="h5">&nbsp;&nbsp;Loading...</Typography>
    </Box>
  )

  return (
    <div>
      {
        loading ? loadingBox : error ? noResult : (
          <div className={classes.root}>
            <ScrollToTop />
            <Grid container xs={12} className={classes.partTop}>
              <Grid item xs={5}>
                <CardMedia
                  component="img"
                  alt="detail_img"
                  // src={process.env.PUBLIC_URL + "/assets/img/DetailImg.png"}
                  src={process.env.PUBLIC_URL + product.image}
                  title="detail_img"
                  className={classes.detailImg}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h5" className={classes.name}>
                  {product.name}
                </Typography>
                <Typography className={classes.color}>
                  {"Couleur : " + product.color}
                </Typography>
                
                <StarsRank note={product.note} />

                {product.countInStock > 0 ? (
                  <Typography className={classes.stock}>En stock</Typography>
                ) : (
                  <Typography className={classes.stock} color="error">Indisponible</Typography>
                )}

                {
                  product.countInStock > 0 && (
                    <div className={classes.addCart}>

                      <Typography className={classes.qty}>Qté</Typography>

                      <TextField
                        className={classes.textField}
                        id="outlined-number"
                        label="Quantité"
                        variant="outlined"
                        type="number"
                        color="secondary"
                        defaultValue="1"
                        size="small"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={qty > 0 && qty}
                        onChange={(e) => setQty(e.target.value)}
                      />

                      <Button
                        color="secondary"
                        variant="contained"
                        className={classes.btnAddCart}
                        // size="small"
                        onClick={addToCartHandler}
                      >
                        Ajouter
                      </Button>
                    </div>
                  )
                }

                <Typography className={classes.livraison}>
                  Livraison : rapide et gratuit
                </Typography>
                <Typography className={classes.paiement}>Paiements : </Typography>
                <Typography>
                  Retours: Remboursement sous 30 jours, l'acheteur paie les frais de
                  retour
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Link to={"/productslist"}>
                  <Button color="secondary" variant="contained">
                    Retour
                  </Button>
                </Link>
              </Grid>
            </Grid>
            <hr className={classes.divider} />
            <CarouselProductInfo />
            <DescriptionProduct product={product} />
          </div>
        )
      }

    </div>
  );
}

export default ProductInfo;
