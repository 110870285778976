import { Button, makeStyles, TextField, Typography, Grid, Paper, Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { detailsUser,updateUserProfile } from '../actions/userActions';
import LoadingBox from '../components/LoadingBox';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(7),
        justifyContent: 'center',
        display: "flex",
        position: "relative",
    },
    containerbox: {
        width: 800,
        justifyContent: "center",
        display: "flex",
    },
    box: {
        width: 400,
        marginTop: theme.spacing(4),
    },
    label: {
        marginBottom: theme.spacing(1),
    },
}))

function ProfileScreen() {
    const classes = useStyles()

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const userSignin = useSelector(state => state.userSignin)
    const { userInfo } = userSignin
    const userDetails = useSelector(state => state.userDetails)
    const { loading, error, user } = userDetails
    const dispatch = useDispatch()

    useEffect(() => {
        if (!user) {
            dispatch(detailsUser(userInfo._id))
        } else {
            setName(user.name)
            setEmail(user.email)
        }
    }, [dispatch, userInfo._id, user]);

    const submitHandler = (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            alert("Password and confirm password are not matched")
        } else {
            dispatch(updateUserProfile({ userId: user._id, name, password }))
        }
    }

    return (
        <div className={classes.root}>
            <form onSubmit={submitHandler}>

                <Grid>
                    {
                        loading ? <LoadingBox></LoadingBox> :
                            error ? <Typography>{error}</Typography> :
                                <Paper className={classes.containerbox}>
                                    <Box className={classes.box}>
                                        <Typography variant="h6">User Profile</Typography>
                                        <br />
                                        <Grid item className={classes.label}>
                                            <label htmlFor="name">Name</label>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                label="name"
                                                color="secondary"
                                                id="name"
                                                type="text"
                                                placeholder="Enter name"
                                                variant="outlined"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                className={classes.input}
                                                fullWidth
                                            >
                                            </TextField>
                                        </Grid>
                                        <br />
                                        <Grid item className={classes.label}>
                                            <label htmlFor="email">Email</label>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                label="email"
                                                color="secondary"
                                                id="email"
                                                type="text"
                                                variant="outlined"
                                                value={user.email}
                                                disabled
                                                className={classes.input}
                                                fullWidth
                                            >
                                            </TextField>
                                        </Grid>
                                        <br />
                                        <Grid item className={classes.label}>
                                            <label htmlFor="password">Password</label>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                label="password"
                                                color="secondary"
                                                id="password"
                                                type="text"
                                                placeholder="Enter password"
                                                onChange={(e) => setPassword(e.target.value)}
                                                variant="outlined"
                                                className={classes.input}
                                                fullWidth
                                            >
                                            </TextField>
                                        </Grid>
                                        <br />
                                        <Grid item className={classes.label}>
                                            <label htmlFor="confirmPassword">Confirm password</label>
                                        </Grid>
                                        <Grid item>
                                            <TextField
                                                label="confirmPassword"
                                                color="secondary"
                                                id="confirmPassword"
                                                type="text"
                                                placeholder="Enter confirm password"
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                variant="outlined"
                                                className={classes.input}
                                                fullWidth
                                            >
                                            </TextField>
                                        </Grid>
                                        <br />
                                        <Grid item>
                                            <Button
                                                type="submit"
                                                color="secondary"
                                                variant="contained"
                                                fullWidth
                                                className={classes.btnupdate}
                                            >Update</Button>
                                        </Grid>
                                    </Box>
                                </Paper>
                    }
                </Grid>

            </form>

        </div>
    )
}

export default ProfileScreen
