import React, { useEffect } from "react";
import {
  Grid,
  List,
  makeStyles,
  Typography,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { Switch, Route } from "react-router-dom";
// import TopBarContainer from '../containers/TopBarContainer'
import Navbar from "../components/Topbar/Navbar";
import Footer from "../components/Footer/Footer";
import routeList from "./route_list";
import ScrollToTop from "../components/ScrollToTop";
import { useDispatch, useSelector } from 'react-redux';
import { listProducts } from '../actions/productActions';
import ProductHorizontal from "../components/Product/ProductHorizontal";


const routeStyles = makeStyles((theme) => ({
  root: {
    minHeight: `calc(100vh - ${theme.spacing(4)}px)`,
    marginBottom: theme.spacing(4),
  },
  content: {
    marginTop: theme.mixins.toolbar.minHeight,
    width: "100%",
    overflow: "hidden",
  },
}));

function Routes() {

  const classes = routeStyles();
  const dispatch = useDispatch();
  const productList = useSelector((state) => state.productList);
  const { loading, error, products } = productList;

  const p = productList.products

  const noResult = (
    <Typography variant="h4" className={classes.oups}>
      Oups ... on dirait qu'il n'y a pas de résultats
    </Typography>
  );

  const loadingBox = (
    <Box className={classes.loading} sx={{ display: 'flex' }}>
      <CircularProgress color="secondary" size={35} />
      <Typography variant="h5">&nbsp;&nbsp;Loading...</Typography>
    </Box>
  )

  useEffect(() => {
    dispatch(listProducts({}));
  }, [dispatch]);


  return (
    <div>
      <div className={classes.root}>
        {/* <ScrollToTop /> */}
        {/* <TopBarContainer /> */}
        <Navbar />
        <div className={classes.content}>
          <Switch>
            {routeList.map((route) => {
              return (
                <Route
                  key={route.path}
                  exact
                  path={route.path}
                  component={route.component}
                />
              );
            })}
          </Switch>
        </div>
      </div>
      {/* <>
        {
          loading ? loadingBox : error ? noResult : (
            <Grid container className={classes.container} spacing={2}>
              <Grid item xs={10}>
                <List>
                  {p.map(product =>
                    <ProductHorizontal
                      key={product._id}
                      product={product} />
                  )}
                </List>

              </Grid>
            </Grid>
          )
        }

      </> */}
      <hr />
      <Footer />
    </div>
  );
}

export default Routes;
