import React from 'react'
import { makeStyles } from "@material-ui/core"; 
import CarouselBar from './components/CarouselBar';
import SousCarouselBar from './components/SousCarouselBar';

const homeStyle = makeStyles((theme)=>({
    header:{
        marginTop : theme.spacing(7)
    },
}))

function Home(){

    const classes = homeStyle()

    return(
        <div className={classes.header}>
            <CarouselBar />
            <SousCarouselBar />
        </div>
    )
}

export default Home;