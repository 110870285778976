import React, { useEffect } from 'react'
import {
    makeStyles,
    Grid,
    Typography,
    List,
    Card,
    CardMedia,
    CardContent,
    Divider,
    Button,
    Paper,
    Stepper,
    Step,
    StepLabel,
    Box,
} from "@material-ui/core";
import Alert from '@mui/material/Alert';
// import { addToCart, removeFromCart } from '../actions/cartActions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// import StarsRank from '../components/StarsRank';
import { createOrder } from '../actions/orderActions';
import { ORDER_CREATE_RESET } from '../constants/orderConstants';

const useStyle = makeStyles((theme) => ({
    root1: {
        display: "flex",
        flexGrow: 1,
        marginTop: theme.spacing(8),
    },
    root: {
        marginTop: theme.spacing(8),
    },
    card: {
        position: "relative",
        display: "flex",
        height: 150,
        width: "fullWidth",
        margin: theme.spacing(2),
        flexGrow: 1,
        cursor: "pointer",
    },
    contentinfo: {
        margin: theme.spacing(3),
    },
    topcontent: {
        display: "flex",
        height: 150,
    },
    botcontent: {
        display: "flex",
        justifyContent: "flex-end",
        height: 30,
        marginBottom: theme.spacing(1),
    },
    container: {
        marginLeft: theme.spacing(6),
    },
    price: {
        display: "flex",
        justifyContent: "flex-end",
    },
    title: {
        flexGrow: 1,
    },
    link: {
        color: "black",
        textDecoration: "none",
        '&:hover': {
            color: "grey",
        }
    },
    divider: {
        height: 25,
        alignItems: "center",
    },
    media: {
        width: 150,
        height: 150,
    },
    delete: {
        marginRight: theme.spacing(2),
        '&:hover': {
            color: "grey",
        }
    },
    qty: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1),
    },
    totalinfo: {
        marginTop: theme.spacing(3),
        marginRight: theme.spacing(6),
        position: "relative",
        width: 430,
        padding: theme.spacing(2),
    },
    paybtn: {
        height: 45,
    },
    subinfo: {
        marginBottom: theme.spacing(2),
        display: "flex",
    },
    paper: {
        padding: theme.spacing(3),
        backgroundColor: "#fdf8fd"
    }
}))

function PlaceOrderScreen(props) {

    const classes = useStyle()

    const cart = useSelector((state) => state.cart)

    if (!cart.paymentMethod) {
        props.history.push('/payment')
    }
    const orderCreate = useSelector((state) => state.orderCreate)
    const { loading, success, error, order } = orderCreate
    const steps = ["Sign In", "Shipping", "Payment", "Place Order"]

    const dispatch = useDispatch()
    const placeOrderHandler = () => {
        dispatch(createOrder({
            ...cart,
            orderItems: cart.cartItems,
        }))
    }


    const toPrice = (num) => Number(num.toFixed(2))
    cart.itemsPrice = toPrice(cart.cartItems.reduce((a, c) => a + c.price * c.qty, 0))
    cart.shippingPrice = 0 //cart.itemPrice > 100 ? toPrice(0) : toPrice(10)
    cart.taxPrice = 0
    cart.totalPrice = cart.itemsPrice + cart.shippingPrice + cart.taxPrice

    useEffect(() => {
        if (success) {
            props.history.push(`/order/${order._id}`)
            dispatch({
                type: ORDER_CREATE_RESET
            })
        }
        window.scrollTo(0,0)
    }, [dispatch, order, props.history, success]);

    return (
        <div className={classes.root}>
            <Grid container item>
                <Box sx={{ width: '100%' }}>
                    <Stepper activeStep={3} color="black">
                        {steps.map((label) => {
                            return (
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                </Box>
            </Grid>
            <Grid className={classes.root1}>

                <Grid container className={classes.container} spaing={2}>
                    <Grid item xs={10}>
                        <Paper className={classes.paper}>

                            <Typography>Shipping</Typography>
                            <br />
                            <p>
                                <Typography>Name : {cart.shippingAddress.fullName}</Typography>
                                <Typography>Address : {cart.shippingAddress.address},
                                    {cart.shippingAddress.city},{cart.shippingAddress.postalCode},{cart.shippingAddress.country}</Typography>
                            </p>
                        </Paper>
                        <br />
                        <Paper className={classes.paper}>
                            <Typography>Payment</Typography>
                            <br />
                            <p>
                                <Typography>Method :  {cart.paymentMethod}</Typography>
                            </p>
                        </Paper>
                        <br />
                        <Paper className={classes.paper}>
                            <Typography>Order Items</Typography>
                            <br />
                            <List>
                                {
                                    cart.cartItems.map((item) => (
                                        <Card elevation={3} className={classes.card}>
                                            <CardMedia
                                                component="img"
                                                alt="product_img"
                                                src={process.env.PUBLIC_URL + item.image}
                                                title="product_image"
                                                className={classes.media}
                                            />
                                            <Grid container className={classes.contentinfo}>
                                                <Grid container className={classes.topcontent} direction="column">
                                                    <Grid>
                                                        <Grid item className={classes.title}>
                                                            <Typography className={classes.name}>
                                                                <Link to={`/productinfo/${item.product}`} className={classes.link}>{item.name}</Link>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    {item.price && (
                                                        <Grid item xs={12}>
                                                            <Typography className={classes.price}>
                                                                {item.price} EUR
                                                            </Typography>
                                                        </Grid>
                                                    )}
                                                </Grid>


                                            </Grid>
                                        </Card>
                                    ))
                                }
                            </List>
                        </Paper>
                    </Grid>
                </Grid>


                {(cart.cartItems.length !== 0) &&
                    <Grid>
                        <Card className={classes.totalinfo}>
                            <Typography variant="h6">Order Summary</Typography> <br />

                            <Grid container item xs={12} className={classes.subinfo} justifyContent="space-between">
                                <Typography variant="body2">Objects ({cart.cartItems.reduce((a, c) => a + c.qty, 0)}) :</Typography>
                                <Typography variant="body2">{cart.itemsPrice.toFixed(2)} €</Typography>
                            </Grid>
                            <Grid container item xs={12} className={classes.subinfo} justifyContent="space-between">
                                <Typography variant="body2">Shipping :</Typography>
                                <Typography variant="body2">{cart.shippingPrice.toFixed(2)} €</Typography>
                            </Grid>
                            <Grid container item xs={12} className={classes.subinfo} justifyContent="space-between">
                                <Typography variant="body2">Tax :</Typography>
                                <Typography variant="body2">{cart.taxPrice.toFixed(2)} €</Typography>
                            </Grid>
                            <Divider className={classes.subinfo} />
                            <Grid container item xs={12} className={classes.subinfo} justifyContent="space-between">
                                <Typography variant="h6">Total</Typography>
                                <Typography variant="h6">{cart.totalPrice.toFixed(2)} €</Typography>
                            </Grid>
                            <CardContent align="center">
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    className={classes.paybtn}
                                    disabled={cart.cartItems.length === 0}
                                    onClick={placeOrderHandler}
                                    fullWidth
                                >
                                    Place Order
                                </Button>
                            </CardContent>
                            {loading && <Typography />}
                            {error && <Alert variant="outlined" severity="error" className={classes.alert}>{error}</Alert>}
                        </Card>
                    </Grid>
                }

            </Grid>
        </div>
    )
}


export default PlaceOrderScreen;
