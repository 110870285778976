import { Fab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Zoom from "@material-ui/core/Zoom";
import { KeyboardArrowUp } from "@material-ui/icons";
import React from "react";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "fixed",
		bottom: theme.spacing(4),
		right: theme.spacing(4),
	},
}));

/**
 * Floating action button that scroll top when clicked
 * @returns
 */
function FABScrollToTop() {
	const classes = useStyles();
	// Note that you normally won't need to set the window ref as useScrollTrigger
	// will default to window.
	// This is only being set here because the demo is in an iframe.
	const trigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 100,
	});

	const handleClick = (event) => {
		window.scrollTo({ top: 0, behavior: "smooth" });
	};

	return (
		<Zoom in={trigger}>
			<div
				onClick={handleClick}
				role="presentation"
				className={classes.root}
			>
				<Fab
					color="secondary"
					size="small"
					aria-label="Revenir en haut de la page"
				>
					<KeyboardArrowUp />
				</Fab>
			</div>
		</Zoom>
	);
}

export default FABScrollToTop;
