import {
    Grid,
    List,
    makeStyles,
    Typography,
    Box,
    CircularProgress,
} from "@material-ui/core";
import React, { useEffect } from "react";
import ProductHorizontal from "./ProductHorizontal";
import Advertisement from "./Advertisement";
import { useSelector, useDispatch } from "react-redux";
import { listProducts } from "../../actions/productActions";


const useStyle = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(7),
    },
    oups: {
        // color: theme.palette.text.secondary,
        textAlign: "center",
        // marginTop: theme.spacing(7),
    },
    container: {
        // marginTop: theme.spacing(7),
    },
    loading: {
        // marginTop: theme.spacing(8),
        justifyContent: "center",
    },
}));

/**
 * Component to display list announcement
 * @param user current user
 * @param announcements announcements list
 * @param announcementsFeatured featured announcemts list
 * @param loading if true, will display a loading state
 */

function ProductsList() {

    const classes = useStyle();

    const dispatch = useDispatch()

    const productList = useSelector((state) => state.productList)

    const { loading, error, products } = productList

    const p = productList.products

    // console.log(Array.isArray(p));
    // console.log(Array.isArray(products));
    // console.log(Array.isArray(p));

    // console.log("p => ");
    // console.log(p);
    // console.log(productList.products.length);
    // console.log(Object.prototype.toString.call(productList.products));
    // console.log("--------------------------------------");
    // console.log(productList.products instanceof Array);
    // console.log("--------------------------------------");


    useEffect(() => {
        dispatch(listProducts())
    }, [dispatch]);

    const noResult = (
        <Typography variant="h4" className={classes.oups}>
            Oups ... on dirait qu'il n'y a pas de résultats
        </Typography>
    );

    const loadingBox = (
        <Box className={classes.loading} sx={{ display: 'flex' }}>
            <CircularProgress color="secondary" size={35} />
            <Typography variant="h5">&nbsp;&nbsp;Loading...</Typography>
        </Box>
    )

    return (
        <div className={classes.root}>
            {
                loading ? loadingBox : error ? noResult : (
                    <Grid container className={classes.container} spacing={2}>
                        <Grid item xs={12}>
                            <List>
                                {p.map(product => 
                                    <ProductHorizontal
                                        key={product._id}
                                        product={product} />
                                )}
                            </List>

                        </Grid>
                        {/* <Grid item xs={2}>
                            <Advertisement />
                        </Grid> */}
                    </Grid>
                )
            }

        </div>
    );
}


export default ProductsList;
