import { createTheme } from "@material-ui/core";

const theme = createTheme({
	palette: {
		primary: {
			main: "#FFFFFF",
			contrastText: "#fff",
		},
		secondary: {
			main: "#000", //5dbbe5
			contrastText: "#fff",
		},
	},
	shape: {
		borderRadius: "0px",
	},
	overrides: {
		MuiButton: {
			root: {
				color: "white",
				borderRadius: 0,
			},
		},
		MuiTypography: {
			h1: {
				fontSize: "3.1em",
				fontWeight: 600,
			},
			h4: {
				fontSize: "1.8em",
				fontWeight: "bold",
			},
		},
		MuiPaper: {
			borderRadius: 0,
		},
		MuiStepIcon: {
            root: {
                color: 'grey',
                '&$active': {
                    color: '#000000',
                },
                '&$completed': {
                    color: '#000000',
                },
            },
        },
	},
});

export default theme;
