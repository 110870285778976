import { CircularProgress, Typography } from '@material-ui/core'
import { Box } from '@mui/system'
import React from 'react'

export default function LoadingBox() {
    return (
        <div>
            <Box sx={{ display: 'flex' }} justifyContent="center">
                <CircularProgress color="secondary" size={35} />
                <Typography variant="h5">&nbsp;&nbsp;Loading...</Typography>
            </Box>
        </div>
    )
}
