import React, { useEffect } from 'react'
import {
    makeStyles,
    Grid,
    Typography,
    List,
    Card,
    CardMedia,
    Divider,
    Paper,
} from "@material-ui/core";
import MuiAlert from '@mui/material/Alert';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { detailsOrder } from '../actions/orderActions';
import LoadingBox from '../components/LoadingBox';
// import Noresult from '../components/NoResult';



const useStyle = makeStyles((theme) => ({
    root1: {
        display: "flex",
        flexGrow: 1,
        marginTop: theme.spacing(8),
    },
    root: {
        marginTop: theme.spacing(8),
    },
    card: {
        position: "relative",
        display: "flex",
        height: 150,
        width: "fullWidth",
        margin: theme.spacing(2),
        flexGrow: 1,
        cursor: "pointer",
    },
    contentinfo: {
        margin: theme.spacing(3),
    },
    topcontent: {
        display: "flex",
        height: 150,
    },
    botcontent: {
        display: "flex",
        justifyContent: "flex-end",
        height: 30,
        marginBottom: theme.spacing(1),
    },
    container: {
        marginLeft: theme.spacing(6),
    },
    price: {
        display: "flex",
        justifyContent: "flex-end",
    },
    title: {
        flexGrow: 1,
    },
    link: {
        color: "black",
        textDecoration: "none",
        '&:hover': {
            color: "grey",
        }
    },
    divider: {
        height: 25,
        alignItems: "center",
    },
    media: {
        width: 150,
        height: 150,
    },
    delete: {
        marginRight: theme.spacing(2),
        '&:hover': {
            color: "grey",
        }
    },
    qty: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1),
    },
    totalinfo: {
        marginTop: theme.spacing(3),
        marginRight: theme.spacing(6),
        position: "relative",
        width: 430,
        padding: theme.spacing(2),
    },
    paybtn: {
        height: 45,
    },
    subinfo: {
        marginBottom: theme.spacing(2),
        display: "flex",
    },
    paper: {
        padding: theme.spacing(3),
        backgroundColor: "#fdf8fd"
    },
    err: {
        marginTop: theme.spacing(8),
    },
    loadingbox: {
        marginTop: theme.spacing(8),
    },
    alert: {
        marginTop: theme.spacing(2),
    }
}))

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function OrderScreen(props) {

    const classes = useStyle()

    const orderId = props.match.params.id
    const orderDetails = useSelector((state) => state.orderDetails)
    const { order, loading, error } = orderDetails

    const dispatch = useDispatch()


    useEffect(() => {
        dispatch(detailsOrder(orderId))
        window.scrollTo(0, 0)
    }, [dispatch, orderId]);

    return loading ? (<LoadingBox className={classes.loadingbox} />) :
        error ? (<Typography className={classes.err}>{error}</Typography>) :
            (
                <div className={classes.root}>
                    <Typography>Order {order._id}</Typography>
                    <Grid className={classes.root1}>

                        <Grid container className={classes.container} spaing={2}>
                            <Grid item xs={10}>
                                <Paper className={classes.paper}>

                                    <Typography>Shipping</Typography>
                                    <br />
                                    <p>
                                        <Typography>Name : {order.shippingAddress.fullName}</Typography>
                                        <Typography>Address : {order.shippingAddress.address},
                                            {order.shippingAddress.city},{order.shippingAddress.postalCode},{order.shippingAddress.country}</Typography>
                                    </p>
                                    {
                                        order.isDelivered ? (
                                            <Alert severity="success" className={classes.alert}>Delivered at {order.deliveredAt}</Alert>
                                        ) : (
                                            <Alert severity="warning" className={classes.alert}>Not Delivered</Alert>
                                        )
                                    }
                                </Paper>
                                <br />
                                <Paper className={classes.paper}>
                                    <Typography>Payment</Typography>
                                    <br />
                                    <p>
                                        <Typography>Method :  {order.paymentMethod}</Typography>
                                    </p>
                                    {
                                        order.isPaid ? (
                                            <Alert severity="success" className={classes.alert}>Paid at {order.paidAt}</Alert>
                                        ) : (
                                            <Alert severity="warning" className={classes.alert}>Not Paid</Alert>
                                        )
                                    }
                                </Paper>
                                <br />
                                <Paper className={classes.paper}>
                                    <Typography>Order Items</Typography>
                                    <br />
                                    <List>
                                        {
                                            order.orderItems.map((item) => (
                                                <Card elevation={3} className={classes.card}>
                                                    <CardMedia
                                                        component="img"
                                                        alt="product_img"
                                                        src={process.env.PUBLIC_URL + item.image}
                                                        title="product_image"
                                                        className={classes.media}
                                                    />
                                                    <Grid container className={classes.contentinfo}>
                                                        <Grid container className={classes.topcontent} direction="column">
                                                            <Grid>
                                                                <Grid item className={classes.title}>
                                                                    <Typography className={classes.name}>
                                                                        <Link to={`/productinfo/${item.product}`} className={classes.link}>{item.name}</Link>
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                            {item.price && (
                                                                <Grid item xs={12}>
                                                                    <Typography className={classes.price}>
                                                                        {item.price} EUR
                                                                    </Typography>
                                                                </Grid>
                                                            )}
                                                        </Grid>


                                                    </Grid>
                                                </Card>
                                            ))
                                        }
                                    </List>
                                </Paper>
                            </Grid>
                        </Grid>


                        {(order.orderItems.length !== 0) &&
                            <Grid>
                                <Card className={classes.totalinfo}>
                                    <Typography variant="h6">Order Summary</Typography> <br />

                                    <Grid container item xs={12} className={classes.subinfo} justifyContent="space-between">
                                        <Typography variant="body2">Objects ({order.orderItems.reduce((a, c) => a + c.qty, 0)}) :</Typography>
                                        <Typography variant="body2">{order.itemsPrice.toFixed(2)} €</Typography>
                                    </Grid>
                                    <Grid container item xs={12} className={classes.subinfo} justifyContent="space-between">
                                        <Typography variant="body2">Shipping :</Typography>
                                        <Typography variant="body2">{order.shippingPrice.toFixed(2)} €</Typography>
                                    </Grid>
                                    <Grid container item xs={12} className={classes.subinfo} justifyContent="space-between">
                                        <Typography variant="body2">Tax :</Typography>
                                        <Typography variant="body2">{order.taxPrice.toFixed(2)} €</Typography>
                                    </Grid>
                                    <Divider className={classes.subinfo} />
                                    <Grid container item xs={12} className={classes.subinfo} justifyContent="space-between">
                                        <Typography variant="h6">Total</Typography>
                                        <Typography variant="h6">{order.totalPrice.toFixed(2)} €</Typography>
                                    </Grid>
                                    {/* <CardContent align="center">
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            className={classes.paybtn}
                                            disabled={order.orderItems.length == 0}
                                            // onClick={placeOrderHandler}
                                            fullWidth
                                        >
                                            Place Order
                                        </Button>
                                    </CardContent> */}
                                </Card>
                            </Grid>
                        }

                    </Grid>
                </div>
            )
}


export default OrderScreen;
