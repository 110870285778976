import {
    Button,
    Grid,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from '@material-ui/core'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listOrderMine } from '../actions/orderActions'
import LoadingBox from '../components/LoadingBox'

const useStyle = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(8),
    },
    tablecontainer: {
        margin: theme.spacing(2),
    },
    btndetails: {
        margin: theme.spacing(1),

    },
}))

function OrderHistoryScreen(props) {

    const classes = useStyle()

    const orderMineList = useSelector(state => state.orderMineList)
    const { loading, error, orders } = orderMineList
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(listOrderMine());
    }, [dispatch]);
    return (
        <div className={classes.root}>
            <Typography>Order History</Typography>
            {loading ? <LoadingBox></LoadingBox> :
                error ? <Typography>{error}</Typography> :
                    (
                        <Grid className={classes.tablecontainer}>
                            <TableContainer>
                                <Table>

                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ID</TableCell>
                                            <TableCell align="right">DATE</TableCell>
                                            <TableCell align="right">TOTAL</TableCell>
                                            <TableCell align="right">PAID</TableCell>
                                            <TableCell align="right">DELIVERED</TableCell>
                                            {/* <TableCell align="right">ACTIONS</TableCell> */}
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {orders && orders.map((order) => (
                                            <TableRow
                                                key={order._id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">{order._id}</TableCell>
                                                <TableCell align="right">{order.createdAt.substring(0, 10)}</TableCell>
                                                <TableCell align="right">{order.totalPrice.toFixed(2)}</TableCell>
                                                <TableCell align="right">{order.isPaid ? order.paidAt.substring(0, 10) : 'No'}</TableCell>
                                                <TableCell align="right">{order.isDelivered ? order.deliveredAt.substring(0, 10) : 'No'}</TableCell>
                                                <Button
                                                    color="secondary"
                                                    variant="contained"
                                                    onClick={() => {
                                                        props.history.push(`/order/${order._id}`)
                                                    }}
                                                    className={classes.btndetails}
                                                >Details</Button>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    )}
        </div>
    )
}

export default OrderHistoryScreen
