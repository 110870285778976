export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST'
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS'
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL'
export const ORDER_CREATE_RESET = 'ORDER_CREATE_RESET'

export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST'
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS'
export const ORDER_DETAILS_FAIL = 'ORDER_DETAILS_FAIL'

export const ORDER_MINE_LIST_REQUEST = 'ORDER_MINE_LIST_REQUEST'
export const ORDER_MINE_LIST_SUCCESS = 'ORDER_MINE_LIST_SUCCESS'
export const ORDER_MINE_LIST_FAIL = 'ORDER_MINE_LIST_FAIL'