import React, { useState } from 'react'
import {
    Button,
    Typography,
    Paper,
    TextField,
    Box,
    Grid,
    Stepper,
    Step,
    StepLabel,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
// import CheckoutSteps from '../components/CheckoutSteps';
import { useDispatch, useSelector } from 'react-redux';
import { saveShippingAddress } from '../actions/cartActions';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(9),
        justifyContent: 'center',
        display: "flex",
        position: "relative",

    },
    containerbox: {
        width: 800,
        justifyContent: "center",
        display: "flex",
    },
    box: {
        width: 400,
        marginTop: theme.spacing(5),
    },
    form: {
        justifyContent: "center",
    },
    title: {
        display: "flex",
        justifyContent: "flex-start",
        marginBottom: theme.spacing(1),
    }
}))

function ShippingAddressScreen(props) {

    const userSignin = useSelector((state) => state.userSignin)
    const { userInfo } = userSignin
    const cart = useSelector((state) => state.cart)
    const { shippingAddress } = cart
    if (!userInfo) {
        props.history.push('/singin')
    }

    const [fullName, setFullName] = useState(shippingAddress.fullName)
    const [address, setAddress] = useState(shippingAddress.address)
    const [city, setCity] = useState(shippingAddress.city)
    const [postalCode, setPostalCode] = useState(shippingAddress.postalCode)
    const [country, setCountry] = useState(shippingAddress.country)

    const steps = ["Sign In", "Shipping", "Payment", "Place Order"]

    const classes = useStyles()

    const dispatch = useDispatch()

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(saveShippingAddress({fullName, address, city, postalCode, country}))
        props.history.push('/payment')
    }


    return (

        <div className={classes.root}>
            {/* <CheckoutSteps step1 step2></CheckoutSteps> */}
            <Grid container direction="row">
                <Grid container item>
                    <Box sx={{ width: '100%' }}>
                        <Stepper activeStep={1} color="black">
                            {steps.map((label) => {
                                return (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Box>
                </Grid>

                <Grid container item className={classes.form}>

                    <form onSubmit={submitHandler} align="center">
                        <Paper className={classes.containerbox}>
                            <Box className={classes.box}>
                                <Typography variant="h6">Shipping Address</Typography>
                                <br />
                                <Grid item>
                                    <label htmlFor="fullName" className={classes.title}>Full Name</label>
                                </Grid>

                                <Grid item>
                                    <TextField
                                        label="full name"
                                        color="secondary"
                                        id="fullName"
                                        value={fullName}
                                        type="text"
                                        variant="outlined"
                                        // InputLabelProps={{
                                        //     shrink: true,
                                        // }}
                                        placeholder="Enter full name"
                                        required
                                        onChange={(e) => setFullName(e.target.value)}
                                        className={classes.input}
                                        fullWidth
                                    ></TextField>

                                </Grid>
                                <br />
                                <Grid item>
                                    <label htmlFor="address" className={classes.title}>Address</label>
                                </Grid>

                                <Grid item>
                                    <TextField
                                        label="address"
                                        color="secondary"
                                        id="address"
                                        value={address}
                                        type="text"
                                        variant="outlined"
                                        // InputLabelProps={{
                                        //     shrink: true,
                                        // }}
                                        placeholder="Enter address"
                                        required
                                        onChange={(e) => setAddress(e.target.value)}
                                        className={classes.input}
                                        fullWidth
                                    ></TextField>

                                </Grid>
                                <br />
                                <Grid item>
                                    <label htmlFor="city" className={classes.title}>City</label>
                                </Grid>

                                <Grid item>
                                    <TextField
                                        label="city"
                                        color="secondary"
                                        id="city"
                                        value={city}
                                        type="text"
                                        variant="outlined"
                                        // InputLabelProps={{
                                        //     shrink: true,
                                        // }}
                                        placeholder="Enter city"
                                        required
                                        onChange={(e) => setCity(e.target.value)}
                                        className={classes.input}
                                        fullWidth
                                    ></TextField>

                                </Grid>
                                <br />
                                <Grid item>
                                    <label htmlFor="postalCode" className={classes.title}>Postal Code</label>
                                </Grid>

                                <Grid item>
                                    <TextField
                                        label="postal code"
                                        color="secondary"
                                        id="postalCode"
                                        value={postalCode}
                                        type="text"
                                        variant="outlined"
                                        // InputLabelProps={{
                                        //     shrink: true,
                                        // }}
                                        placeholder="Enter postal code"
                                        required
                                        onChange={(e) => setPostalCode(e.target.value)}
                                        className={classes.input}
                                        fullWidth
                                    ></TextField>

                                </Grid>
                                <br />
                                <Grid item>
                                    <label htmlFor="country" className={classes.title}>Country</label>
                                </Grid>

                                <Grid item>
                                    <TextField
                                        label="country"
                                        color="secondary"
                                        id="country"
                                        value={country}
                                        type="text"
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        placeholder="Enter country"
                                        required
                                        onChange={(e) => setCountry(e.target.value)}
                                        className={classes.input}
                                        fullWidth
                                    ></TextField>

                                </Grid>
                                <br />
                                <Grid item>
                                    <Button
                                        type="submit"
                                        color="secondary"
                                        variant="contained"
                                        fullWidth
                                        className={classes.btnsignin}
                                    >
                                        Continue
                                    </Button>
                                </Grid>
                            </Box>
                        </Paper>
                    </form>
                </Grid>
            </Grid>

        </div>
    )
}

export default ShippingAddressScreen
