import { AppBar, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
//import DrawerComponent from "./DrawerComponent";
import ToolboxComponent from "./ToolboxComponent";
import NavMenu from "./NavMenu";

const useStyle = makeStyles((theme) => ({
    topbar: {
        color: "white",
        fontWeight: "bold",
    },
    premium: {
        color: theme.palette.primary.main,
    },
    drawer: {
        paddingTop: 0,
        paddingRight: theme.spacing(4),
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(2),
        width: 300,
    },
}));

/**
 * Component to display navigation bar
 * @param user current user
 */
function Navbar() {
    const classes = useStyle();

    const [state, setState] = useState({
        profileMenuIsShown: false,
    });

    const toggleProfileMenu = (open) => (event) => {
        event.preventDefault(); // no change in url
        if (
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }
        setState({ ...state, profileMenuIsShown: open });
    };

    return (
        <>
            <AppBar position="fixed" className={classes.topbar}>
                <ToolboxComponent
                    title={"eMars"}
                    openDrawer={toggleProfileMenu(true)}
                />
                <NavMenu />
            </AppBar>
            
            {/* {user && (
				<DrawerComponent
					user={user}
					isShown={state.profileMenuIsShown}
					onClose={toggleProfileMenu(false)}
					classes={classes}
				/>
			)} */}
        </>
    );
}

export default Navbar;
