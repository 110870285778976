import React, { useState } from "react";
import {
    MenuItem,
    Tab,
    Tabs,
    Paper,
    makeStyles,
    Card,
    Typography,
    MenuList,
    AppBar,
    CardMedia,
    CardContent,
    Divider,
    CardActionArea,
    Popover,
    Grid,
} from '@material-ui/core';
import { Link } from "react-router-dom";


const useStyle = makeStyles((theme) => ({
    root: {
        display: 'flex',
        position: 'relative',
        width: '100%',
        height: "35vh",
    },
    content: {
        flex: 'auto',
        marginLeft: theme.spacing(2)
    },
    media: {
        width: "50vw",
        height: "30vh",
        margin: theme.spacing(1),

    },
    popper: {
        position: "fixed",
        placement: 'bottom-start',
        centered: 'true',
        width: "100%",
    },
    cata: {
        fontSize: "1.2rem",
        marginLeft: theme.spacing(2),
    },
    divider: {
        variant: "middle",
        margin: theme.spacing(2),
    },
    paper: {
        // overflowY: "auto",
        square: 'true',
    },
    home: {
        textDecoration: "none",
        color: "black",
    }

}));

function NavMenu() {
    const [value, setValue] = useState();
    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [expanded, setExpanded] = useState(false);

    const [popperBonPlan, setPopperBonPlan] = useState({ anchorEl: null })
    const [popperHighTech, setPopperHignTech] = useState({ anchorEl: null })
    const [popperTransport, setPopperTransport] = useState({ anchorEl: null })
    const [popperEcran, setPopperEcran] = useState({ anchorEl: null })
    const [popperAccessoire, setPopperAccessoire] = useState({ anchorEl: null })

    const classes = useStyle()

    const handleMenuOpen = (event) => {
        setOpen(true)
        setAnchorEl(event.currentTarget)
    };

    const handleMenuClose = () => {
        setOpen(false)
        setAnchorEl(null)
    };

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <div onMouseLeave={handleMenuClose}>
            <AppBar position="static">
                <Grid>
                    <Tabs
                        value={value}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Link to="/" className={classes.home}>
                            <Tab label="Accueil" />
                        </Link>
                        <Tab
                            label="Bons Plans"
                            onClick={e => setPopperBonPlan({ ...popperBonPlan, anchorEl: e.currentTarget })}
                            aria-owns={open ? "menu-list-bon-plan" : undefined}
                            aria-describedby="menu-list-bon-plan"
                            aria-haspopup={"true"}
                        />
                        <Tab label="High-Tech"
                            onClick={e => setPopperHignTech({ ...popperHighTech, anchorEl: e.currentTarget })}
                            aria-owns={open ? "menu-list-high-tech" : undefined}
                            aria-describedby="menu-list-high-tech"
                            aria-haspopup={"true"}
                        />
                        <Tab label="Transport"
                            onClick={e => setPopperTransport({ ...popperTransport, anchorEl: e.currentTarget })}
                            aria-owns={open ? "menu-list-transport" : undefined}
                            aria-haspopup={"true"}
                        />
                        <Tab label="Écran"
                            onClick={e => setPopperEcran({ ...popperEcran, anchorEl: e.currentTarget })}
                            aria-owns={open ? "menu-list-ecran" : undefined}
                            aria-haspopup={"true"}
                        />
                        <Tab label="Accessoire"
                            onClick={e => setPopperAccessoire({ ...popperAccessoire, anchorEl: e.currentTarget })}
                            aria-owns={open ? "menu-list-accessoire" : undefined}
                            aria-haspopup={"true"}
                        />
                        <Tab label="Plus" />
                    </Tabs>

                    <Popover
                        id="menu-list-bon-plan"
                        open={Boolean(popperBonPlan.anchorEl)}
                        anchorEl={popperBonPlan.anchorEl}
                        onClose={() => setPopperBonPlan({ ...popperBonPlan, anchorEl: null })}
                        className={classes.popper}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item={12}>
                                <Paper variant="outlined" className={classes.paper}>
                                    <Card className={classes.root}>
                                        <Grid item xs={3}>
                                            <CardContent className={classes.content}>
                                                <Typography className={classes.cata}>Catégories</Typography>
                                                <Divider className={classes.divider} />
                                                <MenuList>
                                                    <MenuItem onClick={() => { setPopperBonPlan({ anchorEl: null }) }}>Bon plan du moment</MenuItem>
                                                    <MenuItem onClick={() => { setPopperBonPlan({ anchorEl: null }) }}>Bon plan du hitech</MenuItem>
                                                    <MenuItem onClick={() => { setPopperBonPlan({ anchorEl: null }) }}>Bon plan de l'écran</MenuItem>
                                                </MenuList>
                                            </CardContent>
                                        </Grid>
                                        <Grid item xs={2}></Grid>
                                        <Grid item xs={7}>
                                            <CardActionArea className={classes.media}>
                                                <CardMedia
                                                    component='img'
                                                    alt='menu_image'
                                                    image={process.env.PUBLIC_URL + "/assets/img/NavImg_1.jpeg"}
                                                    title="Menu_bon_plan"
                                                />
                                            </CardActionArea>
                                        </Grid>
                                    </Card>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Popover>

                    <Popover
                        id="menu-list-high-tech"
                        open={Boolean(popperHighTech.anchorEl)}
                        anchorEl={popperHighTech.anchorEl}
                        onClose={() => setPopperHignTech({ ...popperHighTech, anchorEl: null })}
                        className={classes.popper}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item={12}>
                                <Paper variant="outlined" className={classes.paper}>
                                    <Card className={classes.root}>
                                        <Grid item xs={3}>
                                            <CardContent className={classes.content}>
                                                <Typography className={classes.cata}>Catégories</Typography>
                                                <Divider className={classes.divider} />
                                                <MenuList>
                                                    <MenuItem onClick={() => { setPopperHignTech({ anchorEl: null }) }}>Téléphone Mobile</MenuItem>
                                                    <MenuItem onClick={() => { setPopperHignTech({ anchorEl: null }) }}>Informatique</MenuItem>
                                                    <MenuItem onClick={() => { setPopperHignTech({ anchorEl: null }) }}>Projecteur</MenuItem>
                                                    <MenuItem onClick={() => { setPopperHignTech({ anchorEl: null }) }}>Jeu Vidéo</MenuItem>
                                                </MenuList>
                                            </CardContent>
                                        </Grid>
                                        <Grid item xs={2}></Grid>
                                        <Grid item xs={7}>
                                            <CardActionArea className={classes.media}>
                                                <CardMedia
                                                    component='img'
                                                    alt='menu_image'
                                                    image={process.env.PUBLIC_URL + "/assets/img/NavImg_2.jpeg"}
                                                    title="Menu_high-tech"
                                                />
                                            </CardActionArea>
                                        </Grid>
                                    </Card>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Popover>

                    <Popover
                        id="menu-list-transport"
                        open={Boolean(popperTransport.anchorEl)}
                        anchorEl={popperTransport.anchorEl}
                        onClose={() => setPopperTransport({ ...popperTransport, anchorEl: null })}
                        className={classes.popper}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item={12}>
                                <Paper variant="outlined" className={classes.paper}>
                                    <Card className={classes.root}>
                                        <Grid item xs={3}>
                                            <CardContent className={classes.content}>
                                                <Typography className={classes.cata}>Catégories</Typography>
                                                <Divider className={classes.divider} />
                                                <MenuList>
                                                    <MenuItem onClick={() => { setPopperTransport({ anchorEl: null }) }}>Trottinette</MenuItem>
                                                    <MenuItem onClick={() => { setPopperTransport({ anchorEl: null }) }}>Vélo électrique</MenuItem>
                                                    <MenuItem onClick={() => { setPopperTransport({ anchorEl: null }) }}>Segway</MenuItem>
                                                </MenuList>
                                            </CardContent>
                                        </Grid>
                                        <Grid item xs={2}></Grid>
                                        <Grid item xs={7}>
                                            <CardActionArea className={classes.media}>
                                                <CardMedia
                                                    component='img'
                                                    alt='menu_image'
                                                    image={process.env.PUBLIC_URL + "/assets/img/NavImg_3.jpeg"}
                                                    // src={NavImg_3}
                                                    title="Menu_transport"
                                                />
                                            </CardActionArea>
                                        </Grid>
                                    </Card>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Popover>


                    <Popover
                        id="menu-list-ecran"
                        open={Boolean(popperEcran.anchorEl)}
                        anchorEl={popperEcran.anchorEl}
                        onClose={() => setPopperEcran({ ...popperEcran, anchorEl: null })}
                        className={classes.popper}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item={12}>
                                <Paper variant="outlined" className={classes.paper}>
                                    <Card className={classes.root}>
                                        <Grid item xs={3}>
                                            <CardContent className={classes.content}>
                                                <Typography className={classes.cata}>Catégories</Typography>
                                                <Divider className={classes.divider} />
                                                <MenuList>
                                                    <MenuItem onClick={() => { setPopperEcran({ anchorEl: null }) }}>Écran Mural</MenuItem>
                                                    <MenuItem onClick={() => { setPopperEcran({ anchorEl: null }) }}>Écran-vitrine</MenuItem>
                                                    <MenuItem onClick={() => { setPopperEcran({ anchorEl: null }) }}>Totem Non Tactile</MenuItem>
                                                    <MenuItem onClick={() => { setPopperEcran({ anchorEl: null }) }}>All-in-one</MenuItem>
                                                </MenuList>
                                            </CardContent>
                                        </Grid>
                                        <Grid item xs={2}></Grid>
                                        <Grid item xs={7}>
                                            <CardActionArea className={classes.media}>
                                                <CardMedia
                                                    component='img'
                                                    alt='menu_image'
                                                    image={process.env.PUBLIC_URL + "/assets/img/NavImg_4.jpeg"}
                                                    title="Menu_ecran"
                                                />
                                            </CardActionArea>
                                        </Grid>
                                    </Card>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Popover>

                    <Popover
                        id="menu-list-accessoire"
                        open={Boolean(popperAccessoire.anchorEl)}
                        anchorEl={popperAccessoire.anchorEl}
                        onClose={() => setPopperAccessoire({ ...popperAccessoire, anchorEl: null })}
                        className={classes.popper}
                        container={document.body}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Paper variant="outlined" className={classes.paper}>
                                    <Card className={classes.root}>
                                        <Grid item xs={3}>
                                            <CardContent className={classes.content}>
                                                <Typography className={classes.cata}>Catégories</Typography>
                                                <Divider className={classes.divider} />
                                                <MenuList>
                                                    <MenuItem onClick={() => { setPopperAccessoire({ anchorEl: null }) }}>Chargeur et connectique</MenuItem>
                                                    <MenuItem onClick={() => { setPopperAccessoire({ anchorEl: null }) }}>Support mural</MenuItem>
                                                    <MenuItem onClick={() => { setPopperAccessoire({ anchorEl: null }) }}>Protection d'écran</MenuItem>
                                                    <MenuItem onClick={() => { setPopperAccessoire({ anchorEl: null }) }}>Batterie externe</MenuItem>
                                                </MenuList>
                                            </CardContent>
                                        </Grid>
                                        <Grid item xs={2}></Grid>
                                        <Grid item xs={7} >
                                            <CardActionArea className={classes.media}>
                                                <CardMedia
                                                    component='img'
                                                    alt='menu_image'
                                                    image={process.env.PUBLIC_URL + "/assets/img/NavImg_5.jpeg"}
                                                    title="Menu_accesoire"
                                                />
                                            </CardActionArea>
                                        </Grid>
                                    </Card>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Popover>
                </Grid>
            </AppBar>
        </div>

    );
}

export default NavMenu;
