import React from "react";
import Carousel from "react-material-ui-carousel"
import {
    Card,
    CardMedia,
    Grid,
    makeStyles,
} from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
    carousel: {

    },
    banner:{
        
    },
    media:{
        height:"20rem",
        width:"100%",
    },
}))

function Banner(props) {
    if (props.newProp) console.log(props.newProp)
    const totalItems = props.length ? props.length : 3;
    const mediaLength = totalItems - 1;

    const classes = useStyle()

    let items = [];
    // const content = (
    //     <Grid item xs={12 / totalItems} key="content">
    //         <CardContent className="Content">
    //             <Typography className="Title">
    //                 {props.item.Name}
    //             </Typography>

    //             <Typography className="Caption">
    //                 {props.item.Caption}
    //             </Typography>

    //             <Button variant="outlined" className="ViewButton">
    //                 Profitez -{">"}
    //             </Button>
    //         </CardContent>
    //     </Grid>
    // )


    for (let i = 0; i < mediaLength+1; i++) {
        const item = props.item.Items[i];

        const media = (
            <Grid item xs={12 / totalItems} key={item.Name}>
                <CardMedia
                    className={classes.media}
                    image={item.Image}
                    title={item.Name}
                >
                </CardMedia>

            </Grid>
        )
        items.push(media);
    }

    // if (contentPosition === "left") {
    //     items.unshift(content);
    // } else if (contentPosition === "right") {
    //     items.push(content);
    // } else if (contentPosition === "middle") {
    //     items.splice(items.length / 2, 0, content);
    // }

    return (
        <Card raised className="Banner">
            <Grid container spacing={0} className="BannerGrid">
                {items}
            </Grid>
        </Card>
    )
}

const items = [
    {
        Name: "Electronics",
        Caption: "Electrify your friends!",
        Items: [
            {
                Name: "Macbook Pro",
                Image: "https://source.unsplash.com/featured/?macbook"
            },
            {
                Name: "iPhone",
                Image: "https://source.unsplash.com/featured/?iphone"
            },
            {
                Name: "Train",
                Image: "https://source.unsplash.com/featured/?train"
            }
        ]
    },
    {
        Name: "Home Appliances",
        Caption: "Say no to manual home labour!",
        Items: [
            {
                Name: "Washing Machine WX9102",
                Image: "https://source.unsplash.com/featured/?washingmachine"
            },
            {
                Name: "Learus Vacuum Cleaner",
                Image: "https://source.unsplash.com/featured/?vacuum,cleaner"
            },
            {
                Name: "House",
                Image: "https://source.unsplash.com/featured/?house"
            }
        ]
    },
    {
        Name: "Decoratives",
        Caption: "Give style and color to your living room!",
        Items: [
            {
                Name: "Living Room Lamp",
                Image: "https://source.unsplash.com/featured/?lamp"
            },
            {
                Name: "Floral Vase",
                Image: "https://source.unsplash.com/featured/?vase"
            },
            {
                Name: "Car",
                Image: "https://source.unsplash.com/featured/?car"
            }
        ]
    }
]

function CarouselBar() {

    const classes = useStyle()

    return (
        <div style={{color: "#494949" }}>

            <Carousel
                className={classes.carousel}
                autoPlay={true}
                animation={"fade"}
                indicators={true}
                timeout={'200'}
                cycleNavigation={true}
                navButtonsAlwaysVisible={false}
                navButtonsAlwaysInvisible={false}
            >
                {
                    items.map((item, index) => {
                        return <Banner item={item} key={index} contentPosition={item.contentPosition} 
                            className={classes.banner}
                        />
                    })
                }
            </Carousel>

        </div>

    )
}


export default CarouselBar;
