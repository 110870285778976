import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import AutorenewIcon from "@material-ui/icons/Autorenew";
import FavoriteIcon from "@material-ui/icons/Favorite";
import StarsRank from "../StarsRank";
// import AnnouncementFavouriteButtonContainer from "../../containers/Announcement/AnnouncementFavouriteButtonContainer";
// import announcementPriceUnitToText from "../../utils/announcementPriceUnitToText";
// import AnnouncementDrawer from "./AnnouncementDrawer";

const useStyle = makeStyles((theme) => ({
  root: {
    position: "relative",
    display: "flex",
    height: 225,
    margin: theme.spacing(2),
    flexGrow: 1,
    cursor: "pointer",
  },
  media: {
    width: 225,
    height: 225,
  },
  content1: {
    width: 350,
    marginLeft: theme.spacing(3),
  },
  content2: {},
  title: {
    flexGrow: 1,
  },
  name: {
    fontSize: "18px",
  },
  link:{
    color:"black",
    textDecoration:"none",
    '&:hover':{
      color:"grey",
    }
  },
  price: {
    fontSize: "1.2em",
    // marginTop: theme.spacing(3),
    fontWeight: "bold",
  },
  bottom: {
    position: "absolute",
    bottom: 0,
    marginBottom: theme.spacing(3),
    fontStyle: "italic",
  },
  willBeUnfavoured: {
    color: "#cfa9a9 !important",
  },
  stars: {
    display: "flex",
    flexGrow: 1,
  },
  reviews: {
    marginLeft: theme.spacing(0.5),
  },
  extraInfo: {
    fontSize: "0.9em",
    color: "#787878",
    // marginTop: theme.spacing(0.5),
  },
  extraInfoFirst: {
    fontSize: "0.9em",
    color: "#787878",
    marginTop: theme.spacing(1),
  },
  icon: {
    display: "flex",
  },
  textContent2: {
    marginLeft: theme.spacing(2),
    color: "#787878",
    fontSize: "0.9em",
  },
  textIndispo: {
    marginLeft: theme.spacing(2),
    fontSize: "0.9em",
  },
  btn1: {
    width: "14rem",
    fontSize: "0.7rem",
    marginTop: theme.spacing(2),
  },
  btn2: {
    width: "14rem",
    fontSize: "0.7rem",
    marginTop: theme.spacing(2),
  },
}));

/**
 * Component for horizontal announcement style in list
 * @param product current product
 * @returns
 */
// user,
// announcement,
// favouriteEffect = false,

function ProductHorizontal({ product }) {
  const classes = useStyle();

  const [state, setState] = useState({ right: false });
  // const [willBeUnfavoured, setWillBeUnfavoured] = useState(false);

  // const toggleDrawer = (anchor, open) => (event) => {
  // 	if (
  // 		event.type === "keydown" &&
  // 		(event.key === "Tab" || event.key === "Shift")
  // 	) {
  // 		return;
  // 	}
  // 	setState({ ...state, [anchor]: open });
  // };

  // const rootClass =
  // 	favouriteEffect && willBeUnfavoured && classes.willBeUnfavoured;

  return (
    <React.Fragment>
      <Card elevation={3} className={classes.root}>
        <CardActionArea className={classes.media}>
          <Link to={`/productinfo/${product._id}`}>
            <CardMedia
              component="img"
              alt="product_image"
              // image={
              // 	process.env.REACT_APP_API_URL +
              // 	"/static/img/" +
              // 	announcement.imageName
              // }
              // image={product.image}
              src={process.env.PUBLIC_URL + product.image}
              title="product_image"
              className={classes.media}
            />
          </Link>
        </CardActionArea>
        <CardContent className={classes.content1}>
          <Grid container direction="column">
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              xs={12}
              spacing={1}
            >
              <Grid item className={classes.title}>
                <Typography className={classes.name} noWrap>
                  <Link to={`/productinfo/${product._id}`} className={classes.link}>{product.name}</Link>
                </Typography>
              </Grid>
              <Grid container xs={12} className={classes.stars}>
                <Grid item xs={2.5}>
                  <StarsRank note={product.note} />
                </Grid>
                <Grid
                  item
                  xs={1}
                  alignItems="center"
                  className={classes.reviews}
                >
                  <Typography>({product.reviews.length})</Typography>
                </Grid>
              </Grid>
              {/* <Grid item>
								<Chip
									label={announcement.activity.name}
									color={
										favouriteEffect && willBeUnfavoured
											? "#cfa9a9"
											: "primary"
									}
								/>
							</Grid> */}
              {/* {user.data && (
								<Grid item>
									<AnnouncementFavouriteButtonContainer
										user={user}
										announcement={announcement}
										afterSetFavorite={(newValue) =>
											setWillBeUnfavoured(!newValue)
										}
									/>
								</Grid>
							)} */}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.extraInfoFirst}>
              • Taille de la diagoale : 6.1"
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.extraInfo}>
              • Résolution du capteur : 13 mégapixels + 2 mégapixels + 8
              mégapixels
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.extraInfo}>
              • Capacité de la batterie : 4090mAh
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.extraInfo}>• 4 coeurs</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.extraInfo}>• RAM : 3 Go</Typography>
          </Grid>
        </CardContent>
        <CardContent className={classes.content2}>
          {product.price && (
            <Grid item xs={12}>
              <Typography
                className={classes.price}
              // color={
              // 	favouriteEffect && willBeUnfavoured
              // 		? "#cfa9a9"
              // 		: "primary"
              // }
              >
                {product.price} EUR
                {/* {announcementPriceUnitToText(
										announcement.price.unit
									)} */}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12} className={classes.icon}>
            <LocalShippingIcon fontSize="small" />
            <Typography className={classes.textContent2}>
              Livraison gratuite
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.icon}>
            <AddShoppingCartIcon fontSize="small" />
            <Typography className={classes.textContent2}>
              Achat immédiat
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.icon}>
            <AutorenewIcon fontSize="small" />
            {product.countInStock > 0 ? (
              <Typography className={classes.textContent2}>En stock</Typography>
            ) : (
              <Typography className={classes.textIndispo} color="error">
                Indisponible
              </Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button
              color="secondary"
              variant="contained"
              className={classes.btn1}
            >
              Ajouter au panier
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              color="secondary"
              variant="outlined"
              className={classes.btn2}
            >
              <FavoriteIcon fontSize="small" /> &nbsp;&nbsp; Ajouter au favoris
            </Button>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default ProductHorizontal;
