import React, { useState } from "react";
import {
    makeStyles,
    Paper,
    Typography,
} from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
    paper: {
        position: "relative",
        background: "black",
        width: 200,
        height: 500,
        marginTop: theme.spacing(6),
        marginLeft: theme.spacing(2),
    },
}))

function Advertisement() {

    const classes = useStyle()
    return (
        <>
            <Paper className={classes.paper}>
                <Typography color="primary">Ad</Typography>
            </Paper>
        </>
    )

}

export default Advertisement;