import Home from "../Scenes/Home/index"
import ProductsList from "../components/Product/ProductsList"
import ProductInfo from "../components/Product/ProductInfo"
import CartScreen from "../Scenes/CartScreen"
import SigninScreen from "../Scenes/SigninScreen"
import RegisterScreen from "../Scenes/RegisterScreen"
import ShippingAddressScreen from "../Scenes/ShippingAddressScreen"
import PaymentMethodScreen from "../Scenes/PaymentMethodScreen"
import Placeorderscreen from "../Scenes/PlaceOrderScreen"
import OrderScreen from "../Scenes/OrderScreen"
import OrderHistoryScreen from "../Scenes/OrderHistoryScreen"
import ProfileScreen from "../Scenes/ProfileScreen"

// const routeList = [
//     {
//         name:"Home",
//         path:"/",
//         exact:true,
//         render(props){
//             return <Home {...props} />
//         }
//     },
//     {
//         name:"ProductsList",
//         path:"/productslist",
//         exact:true,
//         render(props){
//             return <ProductsListPage {...props} />
//         }
//     },
//     {
//         name:"ProductInfo",
//         path:"/productinfo/:id",
//         exact:true,
//         render(props){
//             return <ProductInfoPage {...props} />
//         },
//     },
// ]

const routeList = [
    {
        path: "/",
        component: Home,
    },
    {
        path: "/productslist",
        component: ProductsList,
    },
    {
        path: '/productinfo/:id',
        component: ProductInfo,
    },
    {
        path: '/cart/:id?',
        component: CartScreen,
    },
    {
        path: '/signin',
        component: SigninScreen,
    },
    {
        path: '/register',
        component: RegisterScreen,
    },
    {
        path: '/shipping',
        component: ShippingAddressScreen,
    },
    {
        path: '/payment',
        component: PaymentMethodScreen,
    },
    {
        path: '/placeorder',
        component: Placeorderscreen,
    },
    {
        path: '/order/:id',
        component: OrderScreen,
    },
    {
        path: '/orderhistory',
        component: OrderHistoryScreen,
    },
    {
        path: '/profile',
        component: ProfileScreen,
    }
]

export default routeList;
