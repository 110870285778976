import React from "react"
import { BrowserRouter } from "react-router-dom"
import IndexRoute from "./router"
import FABScrollToTop from "./components/FABScrollToTop"


const App = () => {
  return (
    <div>
      <BrowserRouter>
        <div className="App">
          <IndexRoute />
          <FABScrollToTop />
        </div>
      </BrowserRouter>
    </div>
  )
}

export default App;
