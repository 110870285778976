import React, { useEffect, useState } from 'react'
import {
    Button,
    Typography,
    Paper,
    Box,
    Grid,
    Stepper,
    Step,
    StepLabel,
    Avatar,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import Radio from '@mui/material/Radio';
import { useDispatch, useSelector } from 'react-redux';
import { savePaymentMethod, saveShippingAddress } from '../actions/cartActions';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(9),
        justifyContent: 'center',
        display: "flex",
        position: "relative",

    },
    containerbox: {
        width: 800,
        justifyContent: "center",
        display: "flex",
    },
    box: {
        width: 400,
        marginTop: theme.spacing(5),
    },
    form: {
        justifyContent: "center",
    },
    title: {
        display: "flex",
        justifyContent: "flex-start",
        marginBottom: theme.spacing(1),
    },
    containerRadios: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    label: {
        marginRight: theme.spacing(2)
    },
    logo: {
        width: 50,
    }
}))

function PaymentMethodScreen(props) {

    const [paymentMethod, setPaymentMethod] = useState('PayPal')

    const userSignin = useSelector(state => state.userSignin)
    const { userInfo } = userSignin
    const cart = useSelector(state => state.cart)
    const { shippingAddress } = cart
    if (!userInfo) {
        props.history.push('/singin')
    }

    const steps = ["Sign In", "Shipping", "Payment", "Place Order"]

    const classes = useStyles()

    const dispatch = useDispatch()

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(savePaymentMethod(paymentMethod))
        props.history.push('/placeorder')
    }
    useEffect(() => {
        window.scrollTo(0,0)
    }, [])


    return (

        <div className={classes.root}>
            {/* <CheckoutSteps step1 step2></CheckoutSteps> */}
            <Grid container direction="row">
                <Grid container item>
                    <Box sx={{ width: '100%' }}>
                        <Stepper activeStep={2} color="black">
                            {steps.map((label) => {
                                return (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Box>
                </Grid>

                <Grid container item className={classes.form}>
                    <form onSubmit={submitHandler} align="center">
                        <Paper className={classes.containerbox}>
                            <Box className={classes.box}>
                                <Typography variant="h6">Payment</Typography>
                                <br />
                                <Grid container direction="column" >
                                    <Grid item className={classes.containerRadios}>
                                        <Radio
                                            onChange={(e) => setPaymentMethod(e.target.value)}
                                            value="PayPal"
                                            name="paymentMethod"
                                            required
                                            checked={paymentMethod === "PayPal"}
                                            className={classes.radios}
                                        />
                                        <label htmlFor="paypal" className={classes.label}>Paypal</label>
                                        <Avatar
                                            className={classes.logo}
                                            src={process.env.PUBLIC_URL + "/assets/logo/paypal-logo.png"}
                                            variant="square"
                                        />

                                    </Grid>

                                    <Grid item className={classes.containerRadios}>
                                        <Radio
                                            onChange={(e) => setPaymentMethod(e.target.value)}
                                            value="Mastercard"
                                            name="paymentMethod"
                                            checked={paymentMethod === "Mastercard"}
                                            required
                                            className={classes.radios}
                                        />
                                        <label htmlFor="mastercard" className={classes.label}>MasterCard</label>
                                        <Avatar
                                            className={classes.logo}
                                            src={process.env.PUBLIC_URL + "/assets/logo/mastercard-logo.png"}
                                            variant="square"
                                        />
                                    </Grid>

                                    <Grid item className={classes.containerRadios}>
                                        <Radio
                                            onChange={(e) => setPaymentMethod(e.target.value)}
                                            value="Other"
                                            name="paymentMethod"
                                            checked={paymentMethod === "Other"}
                                            required

                                        />
                                        <label htmlFor="other" className={classes.label}>Other</label>
                                    </Grid>
                                </Grid>

                                <br />
                                <br />

                                <Grid item>
                                    <Button
                                        type="submit"
                                        color="secondary"
                                        variant="contained"
                                        fullWidth
                                        className={classes.btnpayment}
                                    >
                                        Continue
                                    </Button>

                                </Grid>
                            </Box>
                        </Paper>
                    </form>
                </Grid>
            </Grid>
            
        </div>
    )
}

export default PaymentMethodScreen