import {
	Grid,
	makeStyles,
	Typography,
} from "@material-ui/core";
// import FacebookIcon from "@material-ui/icons/Facebook";
// import YouTubeIcon from '@material-ui/icons/YouTube';
// import TwitterIcon from "@material-ui/icons/Twitter";
import React from "react";
// import { Link } from "react-router-dom";

const useStyle = makeStyles((theme) => ({
	root: {
		width: "100%",
		backgroundColor: theme.palette.primary.main,
		color: "black",
		// paddingTop: theme.spacing(6),
		padding : theme.spacing(8)
	},
	footerItem: {
		[theme.breakpoints.down("sm")]: {
			marginBottom: theme.spacing(4),
		},
	},
	typoSubtitle: {
		fontWeight: "Bold",
		marginBottom:theme.spacing(2),
		color:"grey",
		fontSize:16,
	},
	typo: {
		fontWeight: 200,
		fontSize: 12,
		whiteSpace: "nowrap",
		color: "#767676",
		padding:2,
	},
	copyright: {
		[theme.breakpoints.up("md")]: {
			marginTop: theme.spacing(6),
		},
		fontSize: 12,
	},
	commu:{
		marginTop:theme.spacing(4),
	}
}));

/**
 * Component for the footer on the website
 * @returns
 */
function Footer() {
	const classes = useStyle();

	return (
		<div >
				<Grid container xs={12} className={classes.root}>
					<Grid
						className={classes.footerItem}
						item
						xs={3}
						md
						container
						direction="column"
						alignItems="flex-start"
					>
						<Grid item>
							<Typography className={classes.typoSubtitle}>
								Acheter
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
                            Comment acheter
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
								Acheter par catégories
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
								Garantie client eMars
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
								Promotions
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
								Application eMars
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
								Marques
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
								Marques de voiture
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
								Droits et obligations sur eMars
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
								eMars Extra
							</Typography>
						</Grid>
					</Grid>
					<Grid
						className={classes.footerItem}
						item
						xs={3}
						md
						container
						direction="column"
						alignItems="flex-start"
					>
						<Grid item>
							<Typography className={classes.typoSubtitle}>
								Vendre
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
                            Comment vendre
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
								Outils de vente
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
								Espace vendeurs
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
								Ouvrir une boutique eMars
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
								Espace livraison
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
								Frais de vente
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
								Protection des venteurs
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
								Vendre à l'international
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
								Fonctionnement de la plateforme eMars
							</Typography>
						</Grid>
					</Grid>
					<Grid
						className={classes.footerItem}
						item
						xs={3}
						md
						container
						direction="column"
						alignItems="center"
					>
						<Grid item>
							<Typography className={classes.typoSubtitle}>
								A propos d'eMars
							</Typography>
						</Grid>

						<Grid item>
							<Typography className={classes.typo}>
								Mentions légales
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
								eMars Advertising
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
								Protection des venteurs
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
								Affiliation
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
								VeRO:Propriété Intellectuelle
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
								Programme eMars des developpeurs
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
								Communiqués de presse
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
								Opportunité de carrière
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
								Décisions de justice
							</Typography>
						</Grid>
					</Grid>
					<Grid
						className={classes.footerItem}
						item
						xs={3}
						md
						container
						direction="column"
						alignItems="center"
					>
						<Grid item>
							<Typography className={classes.typoSubtitle}>
								Aide
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
                            Espace Sécurité
							</Typography>
						</Grid>
						
						<Grid item className={classes.commu}>
							<Typography className={classes.typoSubtitle}>
								Communauté
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
								Facebook
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
								Twitter
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
								Youtube
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
								Actualités eMars
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
								Forums d'entraide
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
								Forums de discussion
							</Typography>
						</Grid>
						<Grid item>
							<Typography className={classes.typo}>
								Groupes
							</Typography>
						</Grid>
					</Grid>
					<Grid item container xs={12}>
						<Typography className={classes.copyright}>
							© 2020 - {new Date().getFullYear()} EMARS. Tous droits réservés. 
						</Typography>
					</Grid>
				</Grid>
			
		</div>
	);
}

export default Footer;
