import React, { useEffect } from 'react'
import {
    makeStyles,
    Grid,
    Typography,
    List,
    Card,
    CardMedia,
    CardContent,
    Divider,
    Button,
    Avatar,
} from "@material-ui/core";
import { addToCart, removeFromCart } from '../actions/cartActions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import StarsRank from '../components/StarsRank';

const useStyle = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexGrow: 1,
        marginTop: theme.spacing(8),
    },
    card: {
        position: "relative",
        display: "flex",
        height: 225,
        width: 750,
        margin: theme.spacing(2),
        flexGrow: 1,
        cursor: "pointer",
    },
    contentinfo: {
        margin: theme.spacing(3),
    },
    topcontent: {
        display: "flex",
        height: 150,
    },
    botcontent: {
        display: "flex",
        justifyContent: "flex-end",
        height: 30,
        marginBottom: theme.spacing(1),
    },
    container: {
        marginLeft: theme.spacing(6),
    },
    price: {
        display: "flex",
        justifyContent: "flex-end",
    },
    title: {
        flexGrow: 1,
    },
    link: {
        color: "black",
        textDecoration: "none",
        '&:hover': {
            color: "grey",
        }
    },
    divider: {
        height: 25,
        alignItems: "center",
    },
    media: {
        width: 225,
        height: 225,
    },
    delete: {
        marginRight: theme.spacing(2),
        '&:hover': {
            color: "grey",
        }
    },
    qty: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1),
    },
    totalinfo: {
        marginTop: theme.spacing(3),
        marginRight: theme.spacing(6),
        position: "relative",
        // width: 430,
        padding: theme.spacing(2),
    },
    paybtn: {
        height: 45,
    },
    subinfo: {
        marginBottom: theme.spacing(2),
        display: "flex",
    },
    logo:{
        marginRight: theme.spacing(1),
        width:50,
    }
}))

function CartScreen(props) {

    const classes = useStyle()

    const productId = props.match.params.id;
    const qty = props.location.search
        ? Number(props.location.search.split('=')[1])
        : 1

    const cart = useSelector((state) => state.cart)

    const { cartItems } = cart

    const dispatch = useDispatch()

    useEffect(() => {
        if (productId) {
            dispatch(addToCart(productId, qty))
        }
    }, [dispatch, productId, qty]);

    const removeFromCartHandler = (id) => {
        // delete action
        dispatch(removeFromCart(id));
    };

    const checkoutHandler = () => {
        props.history.push('/signin?redirect=shipping')
    }

    // const price_delivery = 0;

    return (
        <div className={classes.root}>
            {
                cartItems.length === 0
                    ? (
                        <Typography>
                            Votre panier est vide. <Link to="/">Allez faire des courses</Link>
                        </Typography>
                    ) : (
                        <Grid container className={classes.container} spaing={2}>
                            <Grid item xs={8}>
                                <List>
                                    {
                                        cartItems.map((item) => (
                                            <Card elevation={3} className={classes.card}>
                                                <CardMedia
                                                    component="img"
                                                    alt="product_img"
                                                    src={process.env.PUBLIC_URL + item.image}
                                                    title="product_image"
                                                    className={classes.media}
                                                />
                                                <Grid container className={classes.contentinfo}>
                                                    <Grid container className={classes.topcontent} direction="column">
                                                        <Grid>
                                                            <Grid item className={classes.title}>
                                                                <Typography className={classes.name}>
                                                                    <Link to={`/productinfo/${item.product}`} className={classes.link}>{item.name}</Link>
                                                                </Typography>
                                                            </Grid>
                                                            <Grid container xs={12} className={classes.stars}>
                                                                <Grid item xs={2.5}>
                                                                    <StarsRank note={item.note} />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        {item.price && (
                                                            <Grid item xs={12}>
                                                                <Typography className={classes.price}>
                                                                    {item.price} EUR
                                                                </Typography>
                                                            </Grid>
                                                        )}
                                                    </Grid>

                                                    <Grid container className={classes.botcontent} direction="row">
                                                        <Typography
                                                            className={classes.delete}
                                                            onClick={() => removeFromCartHandler(item.product)}
                                                        >
                                                            Supprimer
                                                        </Typography>
                                                        <Divider orientation="vertical" flexItem className={classes.divider} />
                                                        <Typography className={classes.qty}>Qté : </Typography>
                                                        <select
                                                            value={item.qty}
                                                            onChange={(e) =>
                                                                dispatch(
                                                                    addToCart(item.product, Number(e.target.value))
                                                                )
                                                            }
                                                        >
                                                            {[...Array(item.countInStock).keys()].map((x) => (
                                                                <option key={x + 1} value={x + 1}>
                                                                    {x + 1}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                        ))
                                    }
                                </List>
                            </Grid>
                        </Grid>
                    )
            }

            {(cartItems.length !== 0) &&
                <Grid item xs={6}>
                    <Card className={classes.totalinfo}>
                        <CardContent align="center">
                            <Button
                                color="secondary"
                                variant="contained"
                                className={classes.paybtn}
                                disabled={cartItems.length === 0}
                                onClick={checkoutHandler}
                                fullWidth
                            >
                                Finaliser l'achat
                            </Button>
                        </CardContent>
                        <Grid container item xs={12} className={classes.subinfo} justifyContent="space-between">
                            <Typography variant="body2">Objects ({cartItems.reduce((a, c) => a + c.qty, 0)}) :</Typography>
                            <Typography variant="body2">{cartItems.reduce((a, c) => a + c.price * c.qty, 0)} €</Typography>
                        </Grid>
                        <Grid container item xs={12} className={classes.subinfo} justifyContent="space-between">
                            <Typography variant="body2">Livraison</Typography>
                            <Typography variant="body2">Gratuit</Typography>
                        </Grid>
                        <Grid container item xs={12} >
                            <Avatar
                                className={classes.logo}
                                src={process.env.PUBLIC_URL + "/assets/logo/mastercard-logo.png"}
                                variant="square"
                            />
                            <Avatar
                                className={classes.logo}
                                src={process.env.PUBLIC_URL + "/assets/logo/paypal-logo.png"}
                                variant="square"
                            />
                        </Grid>
                        <Divider className={classes.subinfo} />
                        <Grid container item xs={12} className={classes.subinfo} justifyContent="space-between">
                            <Typography variant="h6">Total</Typography>
                            <Typography variant="h6">{cartItems.reduce((a, c) => a + c.price * c.qty, 0)} €</Typography>
                        </Grid>

                    </Card>
                </Grid>
            }



        </div>
    )
}

export default CartScreen;