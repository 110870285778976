import React, { useState } from "react";
import { alpha, makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Button,
  Toolbar,
  Avatar,
  AppBar,
  Typography,
  IconButton,
  InputBase,
  Badge,
  Paper,
  MenuItem,
  Menu,
} from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import SearchIcon from "@material-ui/icons/Search";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { signout } from "../../actions/userActions";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  logo: {
    marginRight: theme.spacing(2),
    width: "10vh",
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "50vw",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
    display:"flex",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    "&$focused": {
      borderWidth: 10,
      borderColor: "black",
    },
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "30ch",
    },
    "&$focused": {
      borderWidth: 20,
      borderColor: "black",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  inscrie: {
    display: "flex",
    marginLeft: theme.spacing(5),
  },
  dropdown: {
    marginTop: theme.spacing(3.5),
  },
  arrow: {
    borderStyle: "solid",
    borderWidth: "0.13em 0.13em 0 0",
    display: "inline-block",
    height: "0.45em",
    left: "0.15em",
    position: "relative",
    top: "0.25em",
    transform: "rotate(135deg)",
    verticalAlign: "center",
    width: "0.45em",
    marginLeft: theme.spacing(1),
  },
  link: {
    color: "black",
    textDecoration: "none",
    display: "flex",
    '&:hover': {
      color: "grey",
    }
  },
  linkbtn: {
    textDecoration: "none",
    width:"10vw",
  },
  username: {
    textDecoration: "none",
    color: "black",
  }
}));

const styles = {
  button: {
    height: "0.5rem",
    weight: "0.2rem",
  }
}

function ToolboxComponent() {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart
  const userSignin = useSelector((state) => state.userSignin)
  const { userInfo } = userSignin

  const handleMouseOver = (e) => {
    setAnchorEl(e.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const dispatch = useDispatch()

  const signoutHandler = () => {
    dispatch(signout())
  }

  return (
    <div className={classes.grow}>
        <AppBar position="static">
          <Toolbar>
            <Link to="/" className={classes.logotext}>
              <Avatar
                className={classes.logo}
                src={process.env.PUBLIC_URL + "/assets/logo/emars_logo.png"}
                onClick={scrollToTop}
                variant="square"
              />
              {/* <Typography
              onClick={scrollToTop}
            // varaint="h4"
            >
              eMars
            </Typography> */}
            </Link>

            <div className={classes.search}>
              <Paper>
                {/* <Grid item xs={12} sm={6} md={4}> */}
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Search…"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  inputProps={{ "aria-label": "search" }}
                />
                <Link to="/productslist" className={classes.linkbtn}>
                  <Button
                    variant="contained"
                    color="secondary"
                    styles={styles.button}
                  >
                    Search
                  </Button>
                </Link>
                {/* </Grid> */}
              </Paper>
            </div>

            <div className={classes.inscrie}>

              {
                userInfo ? (
                  <div>
                    <Link
                      to="#"
                      className={classes.username}
                      onMouseEnter={handleMouseOver}
                    >{userInfo.name}</Link>

                  </div>
                ) : (
                  <Link to="/signin" className={classes.link}>
                    <Typography>S'inscrie</Typography>
                    <Typography className={classes.arrow}></Typography>
                  </Link>
                )
              }

            </div>


            {/* <div className={classes.inscrie} >
            <Link to="/signin" className={classes.link}>
              <Typography onMouseEnter={handleMouseOver}>S'inscrie</Typography>
            </Link>
            <Typography className={classes.arrow}></Typography>

          </div> */}

            <Menu
              className={classes.dropdown}
              anchorEl={anchorEl}
              open={open}
              onMouseLeave={handleClose}
              MenuListProps={{ onMouseLeave: handleClose }}
              // anchorReference="anchorPosition"
              // anchorPosition={{
              //   top: 50, left: 820
              // }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}

            >
              <Link to="#signout" onClick={signoutHandler}>
                <MenuItem>Sign out</MenuItem>
              </Link>
              <Link to='/orderhistory'>Order History</Link>
              <br />
              <Link to='/profile'>User Profile</Link>
              {/* <MenuItem>Mes ventes</MenuItem>
            <MenuItem>Objets achetés</MenuItem> */}
            </Menu>

            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <IconButton aria-label="products" color="inherit">
                <Link to="/cart" className={classes.link}>
                  <Badge badgeContent={cartItems.length} color="secondary">
                    <ShoppingCartIcon />
                  </Badge>
                </Link>
              </IconButton>
              {/* <IconButton aria-label="show 17 new notifications" color="inherit">
              <Badge badgeContent={17} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
              {/* <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton> */}
            </div>
            {/* <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div> */}
          </Toolbar>
        </AppBar>
        {/* {renderMobileMenu}
      {renderMenu} */}
    </div>
  );
}

export default ToolboxComponent;
