import React, { useState } from "react";
import Carousel from "react-material-ui-carousel";
import {
    Card,
    CardMedia,
    Typography,
    Grid,
    makeStyles,
} from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(5),
        marginBottom: "100px",
        color: "#494949",
    },
    media: {
        height: "8rem",
        width: "8rem",
        marginBottom: theme.spacing(1),
    },
    title: {
        display: "flex",
        marginLeft: theme.spacing(1),
        padding: theme.spacing(2),
    },
    price: {
        marginLeft: theme.spacing(1),
    },
    carousel: {
        marginLeft: theme.spacing(2),
    },
    details: {
        wordWrap: "break-word",
    },
}));

function Banner(props) {
    const classes = useStyle();

    const totalItems = props.length ? props.length : 6;
    const mediaLength = totalItems;

    let items = [];

    for (let i = 0; i < mediaLength; i++) {
        const item = props.item.Items[i];

        const media = (
            <Grid item xs={12 / totalItems} key={item.Name}>
                <CardMedia
                    className={classes.media}
                    image={process.env.PUBLIC_URL + "/assets/img/DetailImg.png"}
                // title={item.Name}
                ></CardMedia>
                <Typography variant="subtitle2" className={classes.details}>
                    M12 Pro 6.3" Face ID Fingerprint Smartphone Android 11.0 8G+128GB
                    6800mah Phone
                </Typography>
                <Typography varaint="h6" className={classes.price}>
                    {item.Price} €
                </Typography>
            </Grid>
        );
        items.push(media);
    }

    return (
        <Card raised className="Banner">
            <Grid container spacing={0} className="BannerGrid">
                {items}
            </Grid>
        </Card>
    );
}

const items = [
    {
        Items: [
            {
                Name: "Macbook Pro",
                Price: Math.floor(Math.random() * 50) + 20,
            },
            {
                Name: "iPhone",
                Price: Math.floor(Math.random() * 50) + 20,
            },
            {
                Name: "iPhone",
                Price: Math.floor(Math.random() * 50) + 20,
            },
            {
                Name: "iPhone",
                Price: Math.floor(Math.random() * 50) + 20,
            },
            {
                Name: "iPhone",
                Price: Math.floor(Math.random() * 50) + 20,
            },
            {
                Name: "iPhone",
                Price: Math.floor(Math.random() * 50) + 20,
            },
        ],
    },
    {
        Items: [
            {
                Name: "Macbook Pro",
                Price: Math.floor(Math.random() * 50) + 20,
            },
            {
                Name: "iPhone",
                Price: Math.floor(Math.random() * 50) + 20,
            },
            {
                Name: "iPhone",
                Price: Math.floor(Math.random() * 50) + 20,
            },
            {
                Name: "iPhone",
                Price: Math.floor(Math.random() * 50) + 20,
            },
            {
                Name: "iPhone",
                Price: Math.floor(Math.random() * 50) + 20,
            },
            {
                Name: "iPhone",
                Price: Math.floor(Math.random() * 50) + 20,
            },
        ],
    },
];

function CarouselProductInfo() {
    const classes = useStyle();

    return (
        <div className={classes.root}>
            <Typography className={classes.title}>
                Objets sponsorisés que d'autres membres ont également achetés
            </Typography>
            <Carousel
                className={classes.carousel}
                autoPlay={false}
                animation={"fade"}
                indicators={false}
                timeout={100}
                cycleNavigation={true}
                navButtonsAlwaysVisible={false}
                navButtonsAlwaysInvisible={false}
            >
                {items.map((item, index) => {
                    return (
                        <Banner
                            item={item}
                            key={index}
                            contentPosition={item.contentPosition}
                            className={classes.banner}
                        />
                    );
                })}
            </Carousel>
        </div>
    );
}

export default CarouselProductInfo;
