import React, { useEffect, useState } from 'react'
import {
    Button,
    Typography,
    Paper,
    TextField,
    Box,
    Grid,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/styles';
import Alert from '@mui/material/Alert';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { register } from '../actions/userActions';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(10),
        justifyContent: 'center',
        display: "flex",
        position: "relative",
    },
    containerbox: {
        width: 800,
        justifyContent: "center",
        display: "flex",
    },
    titlename: {
        display: "flex",
        justifyContent: "flex-start",
    },
    titlemail: {
        display: "flex",
        justifyContent: "flex-start",
    },
    titlepsw: {
        display: "flex",
        justifyContent: "flex-start",
    },
    btnregister: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    box: {
        width: 400,
        marginTop: theme.spacing(5),
    },
    register: {
        textDecoration: "none",
        '&:hover': {
            color: "grey",
        }
    },
    alert: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}))

function RegisterScreen(props) {

    const classes = useStyles()

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const redirect = props.location.search
        ? props.location.search.split('=')[1]
        : "/"

    const userRegister = useSelector((state) => state.userRegister)
    const { userInfo, loading, error } = userRegister

    const dispatch = useDispatch()

    const submitHandler = (e) => {
        e.preventDefault();
        if (password !== confirmPassword) {
            alert('Password and confirm password are not match');
            // <Alert variant="outlined" severity="error" className={classes.alert}>Password and confirm password are not match</Alert>
        } else {
            dispatch(register(name, email, password))
        }
    }
    useEffect(() => {
        if (userInfo) {
            props.history.push(redirect)
        }
    }, [props.history, redirect, userInfo]);

    return (
        <div>
            <Grid container className={classes.root}>
                <form onSubmit={submitHandler} align="center">
                    <Paper className={classes.containerbox}>
                        <Box className={classes.box}>
                            <Typography variant="h6">Create you account</Typography>
                            {loading && <Typography></Typography>}
                            {/* {error && <Alert variant="filled" severity="error" className={classes.alert}>{error}</Alert>} */}
                            {error && <Alert variant="outlined" severity="error" className={classes.alert}>{error}</Alert>}

                            <Grid item>
                                <label htmlFor="name" className={classes.titlename}>Name</label>
                            </Grid>
                            <br />
                            <Grid item>
                                <TextField
                                    label="name"
                                    color="secondary"
                                    id="name"
                                    type="text"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    placeholder="Enter name"
                                    required
                                    onChange={(e) => setName(e.target.value)}
                                    className={classes.nameinput}
                                    fullWidth
                                ></TextField>
                            </Grid>
                            <br />
                            <Grid item>
                                <label htmlFor="email" className={classes.titlemail}>Email address</label>
                            </Grid>
                            <br />
                            <Grid item>
                                <TextField
                                    label="email"
                                    color="secondary"
                                    id="email"
                                    type="email"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    placeholder="Enter mail"
                                    required
                                    onChange={(e) => setEmail(e.target.value)}
                                    className={classes.emailinput}
                                    fullWidth
                                ></TextField>
                            </Grid>
                            <br />
                            <Grid item>
                                <label htmlFor="password" className={classes.titlepsw}>Password</label>
                            </Grid>
                            <br />
                            <Grid item>
                                <TextField
                                    label="password"
                                    id="password"
                                    color="secondary"
                                    type="password"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    placeholder="Enter password"
                                    required
                                    onChange={(e) => setPassword(e.target.value)}
                                    className={classes.pswinput}
                                    fullWidth
                                ></TextField>
                            </Grid>
                            <br />
                            <Grid item>
                                <label htmlFor="confirmPassword" className={classes.titlepsw}>Confirm Password</label>
                            </Grid>
                            <br />
                            <Grid item>
                                <TextField
                                    label="confirm password"
                                    id="confirmPassword"
                                    color="secondary"
                                    type="password"
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    placeholder="Enter confirm password"
                                    required
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    className={classes.pswinput}
                                    fullWidth
                                ></TextField>
                            </Grid>
                            <br />
                            <Grid item>
                                <Button type="submit" color="secondary" variant="contained" fullWidth className={classes.btnregister}>Register</Button>
                                <Typography>Already have an account? &nbsp;
                                    <Link to={`/signin?redirect=${redirect}`} className={classes.register}>Sign In</Link>
                                </Typography>
                            </Grid>
                        </Box>
                    </Paper>
                </form>
            </Grid>
        </div>
    )
}

export default RegisterScreen;
